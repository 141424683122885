<template>
  <div id="lom-list">
    <v-dialog v-model="previewRecordPrompt" persistent max-width="80%">
      <student-scholarship-preview
        pageDescription="Student scholarship preview"
        :previewRecordPrompt="previewRecordPrompt"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt($event)"
        :recordData="selectedData"
        v-if="previewRecordPrompt"
      ></student-scholarship-preview>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }} - {{ YearName }}</h2>
        <v-spacer></v-spacer>
        <v-btn
          v-if="CreateButtonFlag"
          color="#1db954"
          to="/student-scholarship/create"
          elevation="30"
          rounded
          class="font-size-h6 mr-3 my-3 white--text d-inline-flex align-center"
        >
          Apply for scholarship
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form
            ref="form2"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="searchForm"
          >
            <v-row wrap align="center">
              <!-- Year Selection -->
              <v-col cols="12" sm="6" md="3" lg="3">
                <label class="my-label">
                  <h6><span class="text-danger">*</span> Year</h6>
                </label>
                <v-autocomplete
                  @change="searchForm"
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" class="d-flex a">
                <v-btn
                  v-if="SearchButtonFlag"
                  :loading="SearchLoadingFlag"
                  @click="searchForm"
                  rounded
                  type="submit"
                  color="primary"
                  elevation="3"
                  class="ml-3 my-primary-button"
                >
                  Search
                </v-btn>

                <h3
                  style="margin-left: 30px; margin-top: 5px"
                  v-if="rows.length > 0"
                >
                  {{ rows.length }} applications found.
                </h3>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                lg="3"
                class="d-flex align-center"
                v-if="rows.length > 0"
              >
                <json-excel
                  v-if="DownloadFlag == 1"
                  :data="rows"
                  :fields="ExcelFields"
                  worksheet="My Worksheet"
                  :name="ExcelFileName"
                >
                  <v-btn color="info">Download</v-btn>
                </json-excel>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-row>
          <v-col
            v-for="(row, index) in CardContent"
            :key="index"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card
              class="custom-card"
              elevation="3"
              :color="row.CardBgColor"
              style="height: 200px"
            >
              <v-card-text>
                <v-row>
                  <v-col class="d-inline pt-0 pb-0" cols="auto">
                    <h1
                      class="pt-0 pb-0"
                      style="color: #ffffff; font-weight: bold"
                    >
                      {{ row.Title }}
                    </h1>
                    <div class="d-flex align-center">
                      <h4 class="pt-0 pb-0 mr-2" style="color: #ffffff">
                        {{ row.Description }}
                      </h4>
                      <!-- <v-icon class="mr-2" large :color="row.IconColor">
                        {{ row.DuesFlag }}
                      </v-icon> -->
                    </div>
                    <v-row align="center" no-gutters>
                      <v-icon class="mr-2" large :color="row.SubTitleIconColor1">
                        {{ row.SubTitleIconName1 }}
                      </v-icon>
                      <h4 class="mb-0" style="color: #ffffff; font-size: 25px">
                        {{ row.SubTitleText1 }} {{ row.SubTitleDescription1 }}
                      </h4>
                    </v-row>
                    <v-row align="center" no-gutters>
                      <v-icon class="mr-2" large :color="row.SubTitleIconColor2">
                        {{ row.SubTitleIconName2 }}
                      </v-icon>
                      <h4 class="mb-0" style="color: #ffffff; font-size: 25px">
                        {{ row.SubTitleText2 }} {{ row.SubTitleDescription2 }}
                      </h4>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="50"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="false"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50],
                }"
              >
                <template v-slot:[`item.StudentApprovalStatusTxt`]="{ item }">
                  <v-chip
                    draggable
                    dark
                    :text-color="item.StudentApprovalStatusTxt"
                    :color="item.StudentApprovalStatusColor"
                    >{{ item.StudentApprovalStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:[`item.ScholarshipStatusTxt`]="{ item }">
                  <v-chip
                    draggable
                    dark
                    :text-color="item.ScholarshipStatusTxt"
                    :color="item.ScholarshipStatusColor"
                    >{{ item.ScholarshipStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:[`item.PhotoPath`]="{ item }">
                  <img width="100" height="100" :src="item.PhotoPath" />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit Student </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#e60b09"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import StudentScholarshipPreview from "@/view/pages/erp/student-scholarship/StudentScholarshipPreview.vue";
import Swal from "sweetalert2";

export default {
  components: { StudentScholarshipPreview },

  mixins: [common],
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SearchButtonFlag: false,
      CreateButtonFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",
      CardContent: [],

      DownloadFlag: 1,
      ExcelFileName: "",
      ExcelFields: "",

      recordToEdit: "",
      record: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      editPageDescription: "Assign LOM President for JCI India",

      LomDataPageTitle: "",
      LomDataPreviewFlag: false,
      LomDataParams: {},

      selectedData: {},
      tableData1: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {
    /*
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Vuetify", route: "alerts" },
        { title: "Form Inputs & Control", route: "autocompletes" },
        { title: "Fileinptus" },
      ]);
      */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    JciYearCode: function () {
      console.log("watch JciYearCode");
      var JciYearCode = this.JciYearCode;
      console.log({ JciYearCode });
      if (JciYearCode > 0) {
        this.pageData();
      }else{
        this.rows = [];
        this.CardContent = [];
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "student_scholarship",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    editData(tr) {
      console.log("editData called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1);

      if (n1 > 0) {
        var url = "/student-scholarship/edit";
        var id = tr.StudentId;
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    pageData() {
      console.log("pageData called");
      this.getJciYearCodeOptions();
      this.getCardDetails();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#d33",
          cancelButtonText: "Cancel",
          cancelButtonColor: "#b2a496",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(tr) {
      console.log("deleteData is called");
      console.log({ tr });

      var id = tr.StudentId;
      console.log({ id });

      // id = 0;
      if (id > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/student/destroy";
        var upload = {
          UserInterface: 1,
          StudentId: id,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
              thisIns.LoadingFlag = false;
            } else {
              thisIns.sweetAlert("error", output, false);
              thisIns.LoadingFlag = false;
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            // thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindlly select one Ledger to delete. ";
        this.sweetAlert("error", message, false);
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    showLomDataPreview(tr) {
      console.log("showLomDataPreview called");
      console.log({ tr });
      this.LomDataPageTitle = tr.LomName + " information for the current year";
      this.LomDataPreviewFlag = true;
      this.LomDataParams = {
        UserInterface: 2,
        LomCode: tr.LomId,
      };
    },
    hideLomDataPreview() {
      console.log("hideLomDataPreview called");
      this.LomDataPreviewFlag = false;
      this.LomDataParams = {};
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.searchForm();
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        this.record = tr;
        this.recordToEdit = LomId;
        this.editPageDescription = " LOM President for " + tr.LomName;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to modifiy";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt(flag) {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");

      var YearCode = this.JciYearCode;
      console.log({ YearCode });

      if (YearCode > 0) {
        this.SearchFlag = true;
        this.rows = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/student/lists";
        var upload = {
          UserInterface: 1,
          Year: this.JciYearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.TableContent;
            console.log({ records });

            thisIns.tableColumns1 = records.TableHeader;
            thisIns.ta = records.TableOptions;

            if (flag == 1) {
              thisIns.rows = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    getCardDetails() {
      console.log("getCardDetails is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;

      var YearCode = this.JciYearCode;
      console.log({ YearCode });

      if (YearCode > 0) {
        this.SearchFlag = true;
        this.CreateButtonFlag = false;
        this.CardContent = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/student/scholarship-info";
        var upload = {
          UserInterface: 1,
          Year: YearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            thisIns.CreateButtonFlag = records.CreateButtonFlag;

            if (flag == 1) {
              thisIns.CardContent = records.CardContent;
              //   thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              //   thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Year is required";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  #card-text {
    text-align: center;
    color: white;
  }
  #card-text h5 {
    font-size: 34px;
  }
  #card-text h2 {
    font-size: 34px;
    font-weight: 900;
  }
  #card-text h1 {
    font-size: 44px;
    font-weight: 900;
  }
}
</style>