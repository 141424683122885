<template>
  <v-card class="elevation-4 preview-card" style="width: 100%">
    <v-app-bar color="primary" dense dark elevate-on-scroll>
      <v-toolbar-title class="text-h5"
        >Student scholarship Preview</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <br />
    <v-card-text>
      <v-card class="mb-4 pa-4">
        <v-row>
          <v-col cols="3" class="text-center">
            <v-img
              :src="records.PhotoPath"
              height="150"
              contain
              style="margin-top: 70px"
            ></v-img>
          </v-col>
          <v-col cols="9">
            <h3 class="section-title text-h5"><b>Student Details</b></h3>
            <v-row class="text-h6">
              <v-col cols="6"
                ><b>Application Number:</b>
                {{ records.ApplicationNumber }}</v-col
              >
              <v-col cols="6"><b>Name:</b> {{ records.StudentName }}</v-col>
              <v-col cols="6"><b>Gender:</b> {{ records.Gender }}</v-col>
              <v-col cols="6"
                ><b>School:</b> {{ records.StudentSchoolName }}</v-col
              >
              <v-col cols="6"
                ><b>Parent Name:</b> {{ records.StudentParentName }}</v-col
              >
              <v-col cols="6"
                ><b>Parent Contact:</b> {{ records.ParentContact }}</v-col
              >
              <v-col cols="6"><b>Category:</b> {{ records.Category }}</v-col>
              <v-col cols="6"
                ><b>Sponsor Name:</b> {{ records.SponserName }}</v-col
              >
              <v-col cols="6">
                <b>Scholarship Type:</b>
                <v-chip
                  :color="records.StudentScholarshipTypeColor"
                  class="white--text"
                >
                  {{ records.StudentScholarshipTypeTxt }}
                </v-chip>
              </v-col>
              <v-col cols="6">
                <b>Approval Status:</b>
                <v-chip
                  :color="records.StudentApprovalStatusColor"
                  class="white--text"
                >
                  {{ records.StudentApprovalStatusTxt }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="6">
          <v-card class="mb-4 pa-4">
            <h3 class="section-title text-h5"><b>Bank Details</b></h3>
            <v-row class="text-h6">
              <v-col cols="12"
                ><b>Account Holder:</b> {{ records.AccountHolderName }}</v-col
              >
              <v-col cols="12"
                ><b>Account Number:</b> {{ records.AccountNumber }}
              </v-col>
              <v-col cols="12"><b>Bank Name:</b> {{ records.BankName }} </v-col>
              <v-col cols="12"
                ><b>Branh Name:</b> {{ records.BranchName }}
              </v-col>
              <v-col cols="12"><b>IFSC Code:</b> {{ records.IfscCode }}</v-col>
              <v-col cols="12">
                <b>Account Status:</b>
                <v-chip
                  :color="records.AccountStatusTxtColor"
                  class="white--text"
                >
                  {{ records.AccountStatusTxt }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mb-4 pa-4">
            <h3 class="section-title text-h5"><b>Mark Details</b></h3>
            <v-row class="text-h6">
              <v-col cols="12"
                ><b>Class:</b> {{ records.ClassName }} th std</v-col
              >
              <v-col cols="12"
                ><b>Percentage:</b> {{ records.Percentage }}%</v-col
              >
              <v-col cols="12"
                ><b>Student Type:</b> {{ records.StudentType }}</v-col
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-card class="mb-4 pa-4">
        <h3 class="section-title text-h5"><b>Attachments</b></h3>
        <v-row class="text-h6">
          <v-col cols="6" md="3" class="text-center">
            <b>Recommendation Letter</b>
            <v-img
              :src="records.RecommendationLetterImagePath"
              height="150"
              contain
            ></v-img>
          </v-col>
          <v-col cols="6" md="3" class="text-center">
            <b>Income Proof</b>
            <v-img
              :src="records.IncomeProofImagePath"
              height="150"
              contain
            ></v-img>
          </v-col>
          <v-col cols="6" md="3" class="text-center">
            <b>Bank Passbook</b>
            <v-img
              :src="records.PassbookPath"
              height="150"
              contain
            ></v-img>
          </v-col>
          <v-col cols="6" md="3" class="text-center">
            <b>Marksheet</b>
            <viewer v-if="MarkImagePath">
              <img width="100" height="100" :src="MarkImagePath" />
            </viewer>
            <v-img
              :src="records.MarkImagePath"
              height="150"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mb-4 pa-4" v-if="records.DescriptionTxt != ''">
        <h5 class="blue--text text--lighten text-h5">Description</h5>
        <p
          class="font-size-h6 mt-3 text-h6"
          v-html="records.DescriptionTxt"
        ></p>
      </v-card>

      <v-card class="mb-4 pa-4">
        <label>
          <h3 class="text-h5">
            <span class="text-danger">*</span><b> Description</b>
          </h3>
        </label>
        <v-textarea
          outlined
          v-model="Description"
          :rules="DescriptionRules"
          name="input-7-4"
          label="Description"
          class="text-h6"
        ></v-textarea>
      </v-card>

      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            @click.prevent="submitForm(1)"
            :loading="ScholarshipLoadingFlag"
            color="#1db954"
            elevation="3"
            rounded
            class="font-size-h5 mx-2 my-3 white--text"
          >
            Approve for scholarship
          </v-btn>
          <v-btn
            @click.prevent="submitForm(2)"
            :loading="EmpowerHerLoadingFlag"
            color="#1db954"
            elevation="3"
            rounded
            class="font-size-h5 mx-2 my-3 white--text"
          >
            Approve for Empower her
          </v-btn>
          <v-btn
            @click.prevent="submitForm(3)"
            :loading="RejectLoadingFlag"
            color="#fa1705"
            elevation="3"
            rounded
            class="font-size-h5 mx-2 my-3 white--text"
          >
            Rejected
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    pageTitle: {
      type: String,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      submitFlag: false,
      LoadingFlag: false,
      Resultflag: false,

      ResultFlag: false,
      records: [],
      Description: "",
      DescriptionRules: [(v) => !!v || "Description is required"],

      ScholarshipLoadingFlag: false,
      RejectLoadingFlag: false,
      EmpowerHerLoadingFlag: false,

      ProgessStart: 0,

      LoadingFlag: false,
      OverlayLoadingFlag: false,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      CardContent: [],
      PageInfo: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      this.pageData();
    },
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      this.pageData();
      this.getStudentDetails();
    },
    pageData() {
      console.log("pageData called");
      this.getStudentDetails();
    },
    getStudentDetails() {
      console.log(" getStudentDetails is called");

      var StudentId = this.recordData.StudentId;
      console.log("StudentId=" + StudentId);

      if (StudentId != "") {
        var upload = {
          UserInterface: 1,
          StudentCode: StudentId,
        };
        console.log({ upload });

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/student/show";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            if (flag == 1) {
              thisIns.records = records;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (SeniorLifeMemberPlan == "") {
          message += "senior life member plan code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(Status) {
      console.log("submitForm called");

      console.log({ Status });

      var tr = this.recordData;
      console.log("tr = " + JSON.stringify(tr));

      if (Status > 0) {
        switch (Status) {
          case 1:
            this.ScholarshipLoadingFlag = true;
            break;

          case 2:
            this.EmpowerHerLoadingFlag = true;
            break;

          case 2:
            this.RejectLoadingFlag = true;
            break;

          default:
            break;
        }

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/student/update-approval-status";
        var upload = {
          UserInterface: Status,
          Student: tr.StudentId,
          Description: this.Description,
          ApplicationNumber: tr.ApplicationNumber,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, false);
              thisIns.closePrompt();
              thisIns.Resultflag = true;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style scoped>
/* #scrollable-container {
  padding: 16px;
  max-height: 80vh;
  overflow-y: auto;
} */
.v-card-text {
  padding: 20px;
}

h3 {
  margin-top: 20px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
}

.text-center {
  text-align: center;
}
.image-border {
  border: 2px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
}
</style>